<div class="page">
  <div class="header">
    <nav class="navbar navbar-dark bg-dark text-light">
      <ul class="nav">
        <li class="nav-item" *ngIf="navigation.showBackButton$ | async">
          <a class="nav-link active p-0  back-icon" [routerLink]="[]" (click)="goBack()"
            ><ion-icon name="chevron-back-sharp" size="large" class="text-light"></ion-icon>
          </a>
        </li>
        <li class="nav-item">
          <h1 class="my-0">Sacred Text Lite</h1>
        </li>
      </ul>
    </nav>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" 
            [ngClass]="{'active': (navigation.category$ | async) == null && (navigation.subcategory$ | async) == null}" 
            [attr.aria-current]="(navigation.category$ | async) == null && (navigation.subcategory$ | async) == null ? 'page' : undefined"
        >
          Home
        </li>
        <li *ngIf="navigation.category$ | async as category" 
          class="breadcrumb-item" 
          [ngClass]="{'active': category != null && (navigation.subcategory$ | async) == null}"
          [attr.aria-current]="category != null && (navigation.subcategory$ | async) == null ? 'page' : undefined"
        >
          {{category}}
        </li>
        <li *ngIf="navigation.subcategory$ | async as subcategory" class="breadcrumb-item"
          [ngClass]="{'active': subcategory != null}"
          [attr.aria-current]="subcategory != null ? 'page' : undefined"
        >
          {{subcategory}}
        </li>
      </ol>
    </nav>
  </div>
  <div class="content">
    <router-outlet></router-outlet>
  </div>
</div>
